<template>
  <div class="content-layout">
    <div class="platform-operation">
      <div>
        <a-button
          v-if="$hasPermissions(['rcs:platform:create'])"
          @click="handleCreatePlatform"
          class="add-btn"
          type="primary"
          >新增</a-button
        >
      </div>
      <div class="filter_container">
        <a-input
          v-model="platformNameFilter"
          placeholder="请输入平台名称"
          class="search-keyword"
          allow-clear
          @keyup.enter.native="doSearch"
        ></a-input>
        <a-button class="search-btn" type="primary" @click="doSearch">搜索</a-button>
      </div>
    </div>
    <a-table
      size="middle"
      :pagination="false"
      :columns="this.$constRepository.platform.columns"
      :data-source="tableData"
      class="platform-table"
      rowKey="id"
    >
      <template slot="secret" slot-scope="scope">
        <template v-if="$hasPermissions(['rcs:platform:secret'])">
          <a-button v-if="!scope.secret" type="link" icon="eye" @click="fetchPlatformSecret(scope)" />
          <span v-else>{{ scope.secret }}</span>
        </template>
        <span v-else>*</span>
      </template>
      <template slot="createdTime" slot-scope="scope">{{ scope | parseTime | orNA }}</template>
      <template slot="lastModificationTime" slot-scope="scope">{{ scope | parseTime | orNA }}</template>
      <template slot="region" slot-scope="scope">
        <span v-if="scope">
          {{ scope.name }}
        </span>
        <span v-else>-</span>
      </template>
      <template slot="operation" slot-scope="scope">
        <a-button v-if="$hasPermissions(['rcs:platform:update'])" class="green-btn" ghost @click="handleEdit(scope)"
          >编辑</a-button
        >
        <a-button
          v-if="$hasPermissions(['rcs:platform:refresh'])"
          class="green-btn"
          ghost
          @click="handleRefreshKey(scope)"
          >刷新私钥</a-button
        >
        <a-popconfirm
          v-if="$hasPermissions(['rcs:platform:delete'])"
          okText="是"
          cancelText="否"
          title="是否删除平台?"
          @confirm="handleDelete(scope)"
        >
          <a-button class="red-btn" ghost>删除</a-button>
        </a-popconfirm>
      </template>
    </a-table>
    <div class="pagination">
      <a-pagination
        :current="pageInfo.page"
        :show-total="(total) => `共 ${pageInfo.totalElements} 条`"
        show-quick-jumper
        show-size-changer
        :total="pageInfo.totalElements"
        @showSizeChange="onShowSizeChange"
        @change="onChange"
        :pageSizeOptions="['10', '20', '30', '40']"
      />
      <br />
    </div>
    <PlatformFormModal ref="platformFormModal" />
  </div>
</template>

<script>
import PlatformFormModal from './components/PlatformFormModal';

export default {
  name: 'PlatformManagement',
  components: { PlatformFormModal },
  data() {
    return {
      platformNameFilter: undefined,
      pageInfo: {
        page: 1,
        size: 10,
        totalElements: 0,
        totalPages: 0,
        currentPage: 0,
      },
      tableData: [],
    };
  },
  methods: {
    handleCreatePlatform() {
      this.$refs.platformFormModal.showCreateDialog(async (platform) => {
        try {
          await this.$apiManager.platform.createPlatform({ platformDto: platform });
          this.$message.success('平台创建成功');
          this.listPageData();
        } finally {
          this.$refs.platformFormModal.hideDialog();
        }
      });
    },
    handleEdit(platform) {
      this.$refs.platformFormModal.showUpdateDialog(platform, async (result) => {
        try {
          await this.$apiManager.platform.updatePlatform({
            id: result.id,
            payload: result,
          });
          this.$message.success('平台更新成功');
          this.listPageData();
        } finally {
          this.$refs.platformFormModal.hideDialog();
        }
      });
    },
    async handleDelete(platform) {
      await this.$apiManager.platform.deletePlatform(platform.id);
      this.$message.success('平台删除成功');
      this.listPageData();
    },
    async fetchPlatformList() {
      const params = {
        name: this.platformNameFilter,
        page: this.pageInfo.page - 1,
        size: this.pageInfo.size,
      };
      const res = await this.$apiManager.platform.getPlatforms(params);
      this.tableData = res.data.elements;
      this.$set(this.pageInfo, 'totalElements', res.data.totalElements);
      this.$set(this.pageInfo, 'totalPages', res.data.totalPages);
      this.$set(this.pageInfo, 'currentPage', res.data.currentPage);
    },
    async listPageData() {
      await this.fetchPlatformList();
      if (this.pageInfo.currentPage + 1 > this.pageInfo.totalPages && this.pageInfo.currentPage) {
        this.pageInfo.page = 1;
        this.fetchPlatformList();
      }
    },
    doSearch() {
      this.pageInfo.page = 1;
      this.listPageData();
    },
    async fetchPlatformSecret(platform) {
      const res = await this.$apiManager.platform.getSecret(platform.id);
      this.$set(platform, 'secret', res.data);
    },
    async handleRefreshKey(platform) {
      const res = await this.$apiManager.platform.refreshPlatform(platform.id);
      Object.assign(platform, res.data);
      this.$set(platform, 'secret', undefined);
    },
    onShowSizeChange(current, pageSize) {
      this.pageInfo.page = 1;
      this.$set(this.pageInfo, 'size', pageSize);
      this.listPageData();
    },
    onChange(pageNumber) {
      this.$set(this.pageInfo, 'page', pageNumber);
      this.listPageData();
    },
  },
  mounted() {
    this.listPageData();
  },
};
</script>

<style lang="less" scoped>
.green-btn {
  color: green;
  border: none;
}
.red-btn {
  color: red;
  border: none;
}
.content-layout {
  height: auto;
  .platform-operation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .add-btn {
      // margin: 10px 0px;
      // margin-left: 3%;
    }
    .search-keyword {
      width: 200px;
    }
    .search-btn {
      // margin: 10px 0px;
      margin-left: 3%;
    }
  }
  .platform-table{
    margin-top: 1%;
  }
  .filter_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 1%;
    .search-keyword {
      width: 200px;
    }
    .search-btn {
      // margin: 10px 0px;
      margin-left: 3%;
    }
  }
}
.pagination {
  margin-top: 3%;
}
</style>
